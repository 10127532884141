export enum ApplyType {
  EMAIL = 2,
  PAGE = 3,
  CONTACT = 7,
  VISIT = 8,
}

export enum ActivityTypeID {
  ACTIVITY = 1,
  CLUB = 2,
  CONTEST = 3,
  INTERN = 4,
  RECRUIT = 5,
  EDUCATION = 6,
}

export enum ActivityTypeText {
  ACTIVITY = 'activity',
  CLUB = 'club',
  CONTEST = 'contest',
  INTERN = 'intern',
  RECRUIT = 'recruit',
  EDUCATION = 'education',
}

export enum ActivityTab {
  DETAIL = 'DETAIL',
  COMMUNITY = 'COMMUNITY',
  REPLY = 'REPLY',
  SCRAP = 'SCRAP',
  SCRAP_STATS = 'SCRAP_STATS',
}

export enum TabRefEnum {
  DETAIL,
  COMMUNITY,
  REPLY,
  SCRAP,
  SCRAP_STATS,
}

export enum PostCollectionID {
  ACTIVITY = 5,
  CONTEST = 7,
  CLUB = 8,
  INTERN = 9,
  BEST_CONTENT = 10,
  POPULAR_ACTIVITY = 11,
  POPULAR_CONTEXT = 12,
  POPULAR_CLUB = 13,
  POPULAR_INTERN = 14,
}

export enum BoardSlug {
  ACTIVITY = 'hothot',
  CONTEST = 'board_wkxI19',
  CLUB = 'honeytips',
  INTERN = 'intern_activity',
  RECRUIT = 'board_xMxk38',
}

export enum PageType {
  ACTIVITY = 1,
  ACTIVITY_REVIEW = 2,
}

export enum DjangoContentType {
  PROFILE = 110,
  RESUME = 111,
}

export enum BestPostFilter {
  RECENT = 'RECENT',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  REPLY_COUNT = 'REPLY_COUNT',
  VOTE_COUNT = 'VOTE_COUNT',
}

export enum RecruitScaleType {
  ONE_DIGIT = 'ONE_DIGIT',
  TEN_DIGIT = 'TEN_DIGIT',
  HUNDRED_DIGIT = 'HUNDRED_DIGIT',
}

export enum ActivityFileTypeValue {
  APPLICATION_INFO = 1,
  POSTER = 2,
  COVER_IMAGE = 3,
  THUMBNAIL = 4,
  LOGO = 5,
  CARD_NEWS = 6,
}

export enum PathType {
  CHANNEL = '/channel',
  ACTIVITY = '/activity',
  COMPANY_ANALYSIS = '/company-analysis',
  GSAT = '/prediction/gsat2025-1',
}

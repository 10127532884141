import { PathType } from './enums'

export const EXCEPT_PATH_SEO: string[] = [
  PathType.CHANNEL,
  PathType.ACTIVITY,
  PathType.COMPANY_ANALYSIS,
  PathType.GSAT,
]

export const EXCEPT_PATH_FLUTTER: string[] = [PathType.CHANNEL]
